import React, { useEffect, useState } from "react";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import folderImage from "./folder-2-svgrepo-com.png";
import { FileIcon, defaultStyles } from "react-file-icon";
import down from "./download-minimalistic-svgrepo-com.png";
import folderIcon from "./folder.svg";
import axios from "axios";
import JSZip from "jszip";
import AWS from "aws-sdk";
import DownloadLoader from "./DowloadLoader";
import localforage from "localforage";
import FolderTree from "./FolderTree";
import "../style.css";
import Header from "../../Layout/Header";
import DynamicContent from "../../Layout/DynamicContent";
const FolderView = ({ slugFileData, getPdf }) => {
  function formatBytes(bytes, decimals = 2) {
    bytes = bytes * 1024;
    if (bytes === 0) return "0 Byte";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const formatDate = (timestamp) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    };
    // add Am or Pm

    return new Date(timestamp).toLocaleString("en-US", options);
  };

  const [loading, setLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  // Function to recursively create main folder and subfolders
  function createMainAndSubfolders(mainFolder, subfolders) {
    // Loop through subfolders and add them to the main folder
    // console.log(subfolders);
    if (subfolders.length !== 0) {
      // alert('ok')
      subfolders?.forEach((subfolder) => {
        const subfolderName = subfolder.name;

        // If subfolder has nested subfolders, recursively create them
        if (subfolder.subfolders && subfolder.subfolders.length > 0) {
          const nestedMainFolder = mainFolder.folder(subfolderName);
          createMainAndSubfolders(nestedMainFolder, subfolder.subfolders);
        } else {
          // Add logic to create subfolder as needed
          // For example, here, we're creating an empty subfolder
          mainFolder.folder(subfolderName);
        }
      });
    }
  }

  // Function to create main folder and initiate recursive subfolder creation
  function initiateFolderCreation(mainFolderName, subfolders) {
    const mainFolder = new JSZip();

    // Call the recursive function to create main folder and subfolders
    createMainAndSubfolders(mainFolder, subfolders);

    // Generate the main folder as a blob
    mainFolder
      .generateAsync({ type: "blob" })
      .then((mainFolderBlob) => {
        // Prompt the user to select a destination folder
        const folderName = mainFolderName;
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(mainFolderBlob);
        downloadLink.download = `${folderName}.zip`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        // Add additional logic as needed, such as sending the blob to the server, etc.
        // console.log("Main folder and subfolders created successfully.");
      })
      .catch((error) => {
        console.error("Error generating main folder:", error);
      });
  }

  const initiateDownload = (blob, name, type) => {
    // Prompt the user to select a destination folder
    const folderName = name;
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${folderName}.zip`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  useEffect(() => {
    const clearLocalForage = async () => {
      // Clear all data in LocalForage
      await localforage.clear();
    };

    // Call the clearLocalForage function when the component mounts
    clearLocalForage();
  }, []);
  const handleDownload = async (
    key,
    name,
    type,
    TotalSize,
    folderId = null,
    subfolders
  ) => {
    setLoading(true);
    const url = "https://api.grandtransfer.io/api/get_pre_sign_url";
    const currentFolder = name;
    const expirationTime = new Date().getTime() + 60 * 60 * 1000; // 1 hour expiration

    if (type != "file") {
      const cacheKey = `${type}_${name}_${TotalSize}`;
      // console.log(cacheKey);

      const cachedData = await localforage.getItem(cacheKey);

      if (cachedData) {
        // Check if the cached item has expired
        const { zipBlob, expirationTime } = cachedData;
        const currentTime = new Date().getTime();

        if (currentTime < expirationTime) {
          // Retrieve data from cache and initiate download
          const cachedBlob = new Blob([zipBlob], {
            type: "application/zip"
          });
          initiateDownload(cachedBlob, name, type);
          setLoading(false);
          return;
        } else {
          // If cached item has expired, remove it from the cache
          await localforage.removeItem(cacheKey);
        }
      }

      await axios
        .get(url, {
          params: {
            link: key,
            type: "folder",
            folderId: folderId
          }
        })
        .then(async (response) => {
          // handle success
          // // console.log(response.data);
          const zip = new JSZip();

          try {
            const downloadUrls = response?.data.urls;
            const totalSize = TotalSize * 1024; // Replace with the actual total size
            let totalBytesDownloaded = 0;

            const responses = await Promise.all(
              downloadUrls.map((url) =>
                axios.get(url.presigned_url, {
                  responseType: "arraybuffer",
                  onDownloadProgress: (progressEvent) => {
                    // console.log("Progress Event:", progressEvent);
                    // Accumulate the bytes downloaded for the total calculation
                    const loadedBytesForFile = progressEvent.bytes;

                    // Increment the bytes downloaded for the current file
                    totalBytesDownloaded += loadedBytesForFile;

                    // Calculate the total progress based on the total size
                    const totalProgress = Math.min(
                      Math.round((totalBytesDownloaded / totalSize) * 100),
                      100
                    );

                    setDownloadProgress(totalProgress);
                    // console.log(`Total Download Progress: ${totalProgress}%`);
                    // You can update a progress bar or perform other actions based on this progress value
                  }
                })
              )
            );
            if (downloadUrls.length === 0) {
              const data = {
                type: "folder",
                name: name,
                folderId: folderId
              };
              getPdf(data);
              setLoading(false);
              initiateFolderCreation(name, subfolders);

              return;
            }
            responses.forEach((response, index) => {
              const fileUrl = downloadUrls[index].file_path;
              const fileName = fileUrl.split("/").pop().split("?")[0];

              // Encode currentFolder to URL
              let currentFolderEncoded = encodeURIComponent(currentFolder);

              // Encode folder names and file names to URL
              let folderParts = fileUrl.split("/");
              let encodedFolderParts = folderParts.map((part) =>
                encodeURIComponent(part)
              );
              let encodedFileName = encodeURIComponent(fileName);

              // Construct the encoded file path
              let encodedFilePath = encodedFolderParts.join("/");

              // Extract relative path excluding the file name
              const relativePath = encodedFilePath.substring(
                encodedFilePath.indexOf(currentFolderEncoded),
                encodedFilePath.lastIndexOf("/")
              );

              // Only add files and folders below the specified current folder
              if (relativePath && relativePath.includes(currentFolderEncoded)) {
                zip.file(
                  `${decodeURIComponent(relativePath)}/${decodeURIComponent(
                    encodedFileName
                  )}`,
                  response.data
                );
              }
            });

            const zipBlob = await zip.generateAsync({ type: "blob" });
            setDownloadProgress(0);
            const cacheData = { zipBlob, expirationTime };

            await localforage.setItem(cacheKey, cacheData);

            // Prompt the user to select a destination folder
            const folderName = name;
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(zipBlob);
            downloadLink.download = `${folderName}.zip`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            const data = {
              type: "folder",
              name: folderName,
              folderId: folderId
            };
            getPdf(data);
            setLoading(false);
          } catch (zipError) {
            console.error("Error generating zip:", zipError);
          }

          // Axios configuration object with onDownloadProgress
        })
        .catch((error) => {
          // handle error
          // console.log(error);
        });

      return;
    }

    if (type === "file") {
      const DownloadUrl = key;
      const fileName = name;

      const cacheKey = `file_${name}`;
      const cachedBlob = await localforage.getItem(cacheKey);

      if (cachedBlob) {
        // Check if the cached item has expired
        const { blob, expirationTime } = cachedBlob;
        const currentTime = new Date().getTime();

        if (currentTime < expirationTime) {
          // Retrieve data from cache and initiate download
          const cachedBlobObject = new Blob([blob], {
            type: "application/octet-stream"
          });

          // download the file
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(cachedBlobObject);
          link.download = fileName;
          link.click();
          setLoading(false);
          return;
        } else {
          // If cached item has expired, remove it from the cache
          await localforage.removeItem(cacheKey);
        }
      }

      let Url = "";

      await axios
        .get(url, {
          params: {
            link: DownloadUrl
          }
        })
        .then((response) => {
          Url = response.data.url;

          //decode base64

          Url = atob(Url);

          const axiosConfig = {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Origin: "https://viewer.grandtransfer.io",
              Referer: "https://viewer.grandtransfer.io/"
            },
            onDownloadProgress: (progressEvent) => {
              // Calculate download progress percentage
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setDownloadProgress(progress);

              // You can update a progress bar or perform other actions based on this progress value
            }
          };
          const totalSize = TotalSize * 1024; // Replace with the actual total size
          let totalBytesDownloaded = 0;

          axios
            .get(Url, {
              responseType: "arraybuffer",
              onDownloadProgress: (progressEvent) => {
                // console.log("Progress Event:", progressEvent);
                // Accumulate the bytes downloaded for the total calculation
                const loadedBytesForFile = progressEvent.bytes;

                // Increment the bytes downloaded for the current file
                totalBytesDownloaded += loadedBytesForFile;

                // Calculate the total progress based on the total size
                const totalProgress = Math.min(
                  Math.round((totalBytesDownloaded / totalSize) * 100),
                  100
                );

                setDownloadProgress(totalProgress);
                // console.log(`Total Download Progress: ${totalProgress}%`);
                // You can update a progress bar or perform other actions based on this progress value
              }
            })
            .then(async (response) => {
              // Create a Blob from the response data
              const blob = new Blob([response.data], {
                type: response.headers["content-type"]
              });
              try {
                const cacheData = { blob, expirationTime };

                await localforage.setItem(cacheKey, cacheData);
              } catch (error) {
                console.error("Error storing file in localforage:", error);
              }
              // Create a link element for download
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;

              // Append link to the document body, trigger click, and remove link
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              const data = {
                type: "fileInFolder",
                name: name,
                folderId: folderId
              };
              getPdf(data);
              setLoading(false);
              setDownloadProgress(0);
              return;
            });
        });
    }
  };

  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(1);

  return (
    <>
      {loading && (
        <DownloadLoader
          downloadProgress={downloadProgress}
          setLoading={setLoading}
        />
      )}

      <>
        <>
          <Header />

          <section className="h-[100vh]">
            <div className="flex items-center pt-[60px] lg:pt-[3.5vw] h-full w-full px-[8%] sm:px-[5%] overflow-hidden relative">
              <img
                src="/assets/images/BG.jpg"
                alt="BG"
                className="h-full w-full absolute top-0 left-0 object-cover z-[-1]"
              />
              <div className="flex  justify-between w-full items-center lg:items-end flex-wrap flex-col-reverse lg:flex-row lg:gap-y-[unset] sm:gap-y-[120px] gap-y-[60px]">
                <div className="relative w-[100%] downloadBlock overflow-hidden sm:w-[50%]   pb-[20px] lg:pb-[2.30555555556vw] lg:w-[26.0416666667vw] lg:rounded-[1.04166666667vw] rounded-[15px] bg-[#FFF]">
                  <div className="w-[100%] ">
                    <div className="flex px-[4%] flex-col gap-y-[6px] lg:gap-y-[0.48611111111vw] z-[5] items-center sticky top-[0vw] pb-[20px] lg:pb-[1.6vw] pt-[20px] lg:pt-[1.66666666667vw] bg-[#fff]">
                      <img
                        src="/assets/images/down.svg"
                        alt="down"
                        className="lg:w-[4.11777777778vw] lg:h-[6.18604166667vw] w-[50px]"
                      />
                      <div className="flex items-center justify-center mb-4">
                        <span className="text-xl inter-regular">
                          {slugFileData?.document_details.name &&
                          slugFileData?.document_details.name.length > 20
                            ? slugFileData.document_details.name.substring(
                                0,
                                20
                              ) + "..."
                            : slugFileData?.document_details.name}
                        </span>
                      </div>
                      <button
                        onClick={() =>
                          handleDownload(
                            slugFileData.link,
                            slugFileData?.document_details.name,
                            "folder",
                            slugFileData?.document_details.size,
                            slugFileData?.document_details.id,
                            slugFileData.document_details.subfolders
                          )
                        }
                        className="px-[20px] py-[8px] hover:bg-[#822252] sm:min-w-[unset] min-w-[200px] transition-all duration-300 flex lg:px-[0.90277777777vw] lg:py-[0.48611111111vw] bg-[#992861] lg:items-center lg:gap-x-[0.34722222222vw] gap-x-[5px] lg:rounded-[0.41666666666vw] rounded-[6px]"
                      >
                        <img
                          src="/assets/images/down-white.svg"
                          alt="down "
                          className="lg:w-[1.11111111111vw] btn-svg-icon"
                        />
                        <span className="text14 text-[#FFF]">
                          Download (
                          {formatBytes(slugFileData?.document_details.size)})
                        </span>
                      </button>
                    </div>
                    <div className="max-h-[50vh] px-[4%] overflow-auto">
                      <div className="mt-[10px]  sm:mt-[20px] lg:mt-[1.69444444444vw] flex flex-col gap-y-[15px] lg:gap-y-[1.52777777778vw]">
                        <span className="text14 font-semibold">
                          {" "}
                          Folder Content{" "}
                        </span>
                        <FolderTree
                          slugFileData={slugFileData}
                          handleDownload={handleDownload}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <DynamicContent />
              </div>
            </div>
          </section>
        </>
      </>
    </>
  );
};

export default FolderView;
